<template>
  <div
    class="popup_wrap"
    style="width:800px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <form id="mailFrm">
      <div class="popup_cont">
        <h1 class="page_title">{{ $t('msg.ONEX010P040.001') }}</h1><!-- 출발지/도착지 스케줄 정보 -->
        <div class="content_box"><!-- content_box -->
          <table class="tbl_search">
            <colgroup>
              <col width="100px">
              <col>
              <col width="60px">
              <col>
              <col v-if="lang === 'ENG' || lang === 'CHN' || lang === 'JPN'" width="90px">
              <col v-else width="60px">
              <col>
            </colgroup>
            <tbody>
              <tr v-if="parentInfo.bkgNo !== ''">
                <th>{{ $t('msg.ONEX010P040.002') }}</th><!-- Booking No. -->
                <td>{{ bkgData.bkgNo }}</td>
                <th>{{ $t('msg.ONEX010P040.003') }}</th><!-- 출발지 -->
                <td>{{ bkgData.por.split(',')[0] }}</td>
                <th>{{ $t('msg.ONEX010P040.004') }}</th><!-- 도착지 -->
                <td>{{ bkgData.dly.split(',')[0] }}</td>
              </tr>
              <tr v-else>
                <th>{{ $t('msg.ONEX010P040.012') }}</th><!-- B/L No. -->
                <td>{{ parentInfo.blNo }}</td>
                <th>{{ $t('msg.ONEX010P040.003') }}</th><!-- 출발지 -->
                <td>{{ parentInfo.polPortNm }}</td>
                <th>{{ $t('msg.ONEX010P040.004') }}</th><!-- 도착지 -->
                <td>{{ parentInfo.podPortNm }}</td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box// -->
        <div class="content_box mt10"><!-- content_box -->
          <table class="tbl_col">
            <colgroup>
              <col style="width:19%">
              <col style="width:19%">
              <col style="width:19%">
              <col style="width:19%">
              <col style="width:14%">
              <col style="width:10%">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('msg.ONEX010P040.005') }}<!-- KIND --></th>
                <th>{{ $t('msg.ONEX010P040.006') }}<!-- Original(Proforma) --></th>
                <th>{{ $t('msg.ONEX010P040.007') }}<!-- Revised --></th>
                <th>{{ $t('msg.ONEX010P040.008') }}<!-- Actual --></th>
                <th>{{ $t('msg.ONEX010P040.009') }}<!-- Delay(Hours) --></th>
                <th>{{ $t('msg.ONEX010P040.010') }}<!-- File --></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>POL ETD</td>
                <td>{{ polProDT }}</td>
                <td>{{ polRvsDT }}</td>
                <td>{{ polActDT }}</td>
                <td>{{ polSchVo.diff }}</td>
                <td>
                  <button
                    v-if="$ekmtcCommon.isNotEmpty(params.bkgNo) && vslDelayReasonBtnFlag"
                    class="tbl_icon pdf"
                    @click.prevent="openPopup('DelayNoticePop')"
                  >첨부</button>
                </td>
              </tr>
              <tr>
                <td>POD(DLY) ETA</td>
                <td>{{ podProDT }}</td>
                <td>{{ podRvsDT }}</td>
                <td>{{ podActDT }}</td>
                <td>{{ podSchVo.diff }}</td>
                <td>
                  <button
                    v-if="$ekmtcCommon.isNotEmpty(params.blNo) && vslDelayReasonBtnFlag"
                    class="tbl_icon pdf"
                    @click.prevent="openPopup('DelayNoticePop')"
                  >첨부</button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2"></td>
                <th colspan="2">{{ $t('msg.ONEX010P040.011') }}<!-- Total Transit Time --></th>
                <td colspan="2">{{ transitTime }}</td>
              </tr>
              </tfoot>
          </table>
        </div><!-- content_box // -->
        <win-layer-pop class="pdf_download_pop" style="z-index: 99999;">
          <component
            v-if="customComponent"
            :is="customComponent"
            @close="closePopup"
            :parent-info="popupParams"
          />
        </win-layer-pop>
      </div>
    </form>
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import exportSvc from '@/api/rest/trans/exportSvc'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import schedule from '@/api/rest/schedule/schedule'

export default {
  name: 'PorDlySchedulePop',
  components: {
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    DelayNoticePop: () => import('@/pages/pdf/popup/DelayNoticePop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: '',
          kind: '',
          blNo: '',
          polPortNm: '',
          podPortNm: '',
          polPortCd: '',
          podPortCd: '',
          vslCd: '',
          voyNo: ''
        }
      }
    }
  },
  data () {
    return {
      // PDF 다운로드 팝업 컴포넌트
      customComponent: null,
      // 스케쥴 구독 저장 API 실행 여부
      isProcess: false,
      params: {
        bkgNo: '',
        kind: '',
        keyword: '',
        blNo: '',
        polPortNm: '',
        podPortNm: '',
        polPortCd: '',
        podPortCd: '',
        vslCd: '',
        voyNo: ''
      },
      bkgData: {
        por: '',
        dly: ''
      },
      polSchVo: {},
      podSchVo: {},
      polProDT: '',
      podProDT: '',
      polRvsDT: '',
      podRvsDT: '',
      polActDT: '',
      podActDT: '',
      transitTime: '',

      // 선박 지연 여부
      vslDelayReasonBtnFlag: false,
      popupParams: {
        items: []
      },
      lang: 'KOR'
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.auth !== undefined) {
      this.lang = this.auth.serviceLang
    }
    this.initParam()
  },
  methods: {
    // init parameter
    async initParam () {
      this.params = { ...this.params, ...this.parentInfo }
      this.params.keyword = this.parentInfo.bkgNo
      if (this.$ekmtcCommon.isNotEmpty(this.params.bkgNo)) {
        await this.searchAct(this.params)
        await this.getVslDelayReason('O')
      } else if (this.$ekmtcCommon.isNotEmpty(this.params.blNo)) {
        await this.searchAct4BL()
        await this.getVslDelayReason('I')
      }
    },
    async searchAct (searchParams) {
      await exportSvc.getOnGoingList(searchParams).then(async (res) => {
        if (res.data[0]) {
          this.bkgData = res.data[0]

          this.params.vslCd = this.bkgData.vslCd
          this.params.voyNo = this.bkgData.voyNo
          this.params.polPortCd = this.bkgData.polPortCd
          this.params.podPortCd = this.bkgData.podPortCd
        }

        await vslScheduleInfo.getPorDlyScheduleInfo({ bkgNo: this.params.bkgNo }).then(response => {
          if (response.data.porDlyScheduleInfo) {
            for (const item of response.data.porDlyScheduleInfo) {
              if (item.kind === 'POL') {
                this.polSchVo = item
              } else if (item.kind === 'POD') {
                this.podSchVo = item
              }
            }

            if (this.polSchVo) {
              if (this.$ekmtcCommon.isNotEmpty(this.polSchVo.prfmEndpDt)) {
                this.polProDT = this.$ekmtcCommon.changeDatePattern(this.polSchVo.prfmEndpDt + this.polSchVo.prfmEndpTm, '.')
              }
              if (this.$ekmtcCommon.isNotEmpty(this.polSchVo.rvsdEndpDt)) {
                this.polRvsDT = this.$ekmtcCommon.changeDatePattern(this.polSchVo.rvsdEndpDt + this.polSchVo.rvsdEndpTm, '.')
              }
              if (this.$ekmtcCommon.isNotEmpty(this.polSchVo.actualDt)) {
                this.polActDT = this.$ekmtcCommon.changeDatePattern(this.polSchVo.actualDt + this.polSchVo.actualTm, '.')
              }
            }

            if (this.podSchVo) {
              if (this.$ekmtcCommon.isNotEmpty(this.podSchVo.prfmEndpDt)) {
                this.podProDT = this.$ekmtcCommon.changeDatePattern(this.podSchVo.prfmEndpDt + this.podSchVo.prfmEndpTm, '.')
              }
              if (this.$ekmtcCommon.isNotEmpty(this.podSchVo.rvsdEndpDt)) {
                this.podRvsDT = this.$ekmtcCommon.changeDatePattern(this.podSchVo.rvsdEndpDt + this.podSchVo.rvsdEndpTm, '.')
              }
              if (this.$ekmtcCommon.isNotEmpty(this.podSchVo.actualDt)) {
                this.podActDT = this.$ekmtcCommon.changeDatePattern(this.podSchVo.actualDt + this.podSchVo.actualTm, '.')
              }
            }
          }

          this.transitTime = response.data.transitTime ?? '0'
        })
      })
    },
    async searchAct4BL () {
      await vslScheduleInfo.getPorDlyScheduleInfo({ blNo: this.params.blNo }).then(response => {
        if (response.data.porDlyScheduleInfo) {
          for (const item of response.data.porDlyScheduleInfo) {
            if (item.kind === 'POL') {
              this.polSchVo = item
            } else if (item.kind === 'POD') {
              this.podSchVo = item
            }
          }

          if (this.polSchVo) {
            if (this.$ekmtcCommon.isNotEmpty(this.polSchVo.prfmEndpDt)) {
              this.polProDT = this.$ekmtcCommon.changeDatePattern(this.polSchVo.prfmEndpDt + this.polSchVo.prfmEndpTm, '.')
            }
            if (this.$ekmtcCommon.isNotEmpty(this.polSchVo.rvsdEndpDt)) {
              this.polRvsDT = this.$ekmtcCommon.changeDatePattern(this.polSchVo.rvsdEndpDt + this.polSchVo.rvsdEndpTm, '.')
            }
            if (this.$ekmtcCommon.isNotEmpty(this.polSchVo.actualDt)) {
              this.polActDT = this.$ekmtcCommon.changeDatePattern(this.polSchVo.actualDt + this.polSchVo.actualTm, '.')
            }
          }

          if (this.podSchVo) {
            if (this.$ekmtcCommon.isNotEmpty(this.podSchVo.prfmEndpDt)) {
              this.podProDT = this.$ekmtcCommon.changeDatePattern(this.podSchVo.prfmEndpDt + this.podSchVo.prfmEndpTm, '.')
            }
            if (this.$ekmtcCommon.isNotEmpty(this.podSchVo.rvsdEndpDt)) {
              this.podRvsDT = this.$ekmtcCommon.changeDatePattern(this.podSchVo.rvsdEndpDt + this.podSchVo.rvsdEndpTm, '.')
            }
            if (this.$ekmtcCommon.isNotEmpty(this.podSchVo.actualDt)) {
              this.podActDT = this.$ekmtcCommon.changeDatePattern(this.podSchVo.actualDt + this.podSchVo.actualTm, '.')
            }
          }
        }

        this.transitTime = response.data.transitTime ?? '0'
      })
    },
    async getVslDelayReason (eiCatCd) {
      const params = {
        vslCd: this.params.vslCd,
        voyNo: this.params.voyNo,
        polPortCd: this.params.polPortCd,
        eiCatCd: eiCatCd
      }

      await schedule.getVslDelayReason(params)
        .then(response => {
          if (response.data) {
            this.vslDelayReasonBtnFlag = !this.vslDelayReasonBtnFlag
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // PDF 다운로드 팝업 열기
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }

      this.customComponent = compNm

      if (this.$ekmtcCommon.isNotEmpty(this.params.bkgNo)) {
        this.popupParams.inOut = 'O'
        this.popupParams.items = [
          {
            docNo: '01',
            prtGubun: '28',
            parm1: this.params.bkgNo
          }
        ]
      } else {
        this.popupParams.inOut = 'I'
        this.popupParams.items = [
          {
            docNo: '01',
            prtGubun: '03BL',
            parm1: this.params.blNo
          }
        ]
      }

      this.$ekmtcCommon.layerOpen('.pdf_download_pop')
    },
    // PDF 다운로드 팝업 닫기
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.pdf_download_pop')
    }
  }
}
</script>

<style scoped>
  .tbl_search th {vertical-align: top;}
</style>
