var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("form", { attrs: { id: "mailFrm" } }, [
        _c(
          "div",
          { staticClass: "popup_cont" },
          [
            _c("h1", { staticClass: "page_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX010P040.001"))),
            ]),
            _c("div", { staticClass: "content_box" }, [
              _c("table", { staticClass: "tbl_search" }, [
                _c("colgroup", [
                  _c("col", { attrs: { width: "100px" } }),
                  _c("col"),
                  _c("col", { attrs: { width: "60px" } }),
                  _c("col"),
                  _vm.lang === "ENG" || _vm.lang === "CHN" || _vm.lang === "JPN"
                    ? _c("col", { attrs: { width: "90px" } })
                    : _c("col", { attrs: { width: "60px" } }),
                  _c("col"),
                ]),
                _c("tbody", [
                  _vm.parentInfo.bkgNo !== ""
                    ? _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P040.002"))),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.bkgData.bkgNo))]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P040.003"))),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.bkgData.por.split(",")[0])),
                        ]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P040.004"))),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.bkgData.dly.split(",")[0])),
                        ]),
                      ])
                    : _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P040.012"))),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.parentInfo.blNo))]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P040.003"))),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.parentInfo.polPortNm))]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P040.004"))),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.parentInfo.podPortNm))]),
                      ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "content_box mt10" }, [
              _c("table", { staticClass: "tbl_col" }, [
                _vm._m(0),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P040.005")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P040.006")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P040.007")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P040.008")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P040.009")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P040.010")))]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("POL ETD")]),
                    _c("td", [_vm._v(_vm._s(_vm.polProDT))]),
                    _c("td", [_vm._v(_vm._s(_vm.polRvsDT))]),
                    _c("td", [_vm._v(_vm._s(_vm.polActDT))]),
                    _c("td", [_vm._v(_vm._s(_vm.polSchVo.diff))]),
                    _c("td", [
                      _vm.$ekmtcCommon.isNotEmpty(_vm.params.bkgNo) &&
                      _vm.vslDelayReasonBtnFlag
                        ? _c(
                            "button",
                            {
                              staticClass: "tbl_icon pdf",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openPopup("DelayNoticePop")
                                },
                              },
                            },
                            [_vm._v("첨부")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("POD(DLY) ETA")]),
                    _c("td", [_vm._v(_vm._s(_vm.podProDT))]),
                    _c("td", [_vm._v(_vm._s(_vm.podRvsDT))]),
                    _c("td", [_vm._v(_vm._s(_vm.podActDT))]),
                    _c("td", [_vm._v(_vm._s(_vm.podSchVo.diff))]),
                    _c("td", [
                      _vm.$ekmtcCommon.isNotEmpty(_vm.params.blNo) &&
                      _vm.vslDelayReasonBtnFlag
                        ? _c(
                            "button",
                            {
                              staticClass: "tbl_icon pdf",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openPopup("DelayNoticePop")
                                },
                              },
                            },
                            [_vm._v("첨부")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("tfoot", [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }),
                    _c("th", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX010P040.011"))),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.transitTime)),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "win-layer-pop",
              {
                staticClass: "pdf_download_pop",
                staticStyle: { "z-index": "99999" },
              },
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.popupParams },
                      on: { close: _vm.closePopup },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "19%" } }),
      _c("col", { staticStyle: { width: "19%" } }),
      _c("col", { staticStyle: { width: "19%" } }),
      _c("col", { staticStyle: { width: "19%" } }),
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }